import React from 'react';
import { motion } from 'framer-motion';
import { FloatButton, Input,Button,Modal,Select  } from 'antd';
import { Helmet } from 'react-helmet';
import debounce from 'lodash.debounce';
import apiRequest from '../../../services';
import throwNotification from '../../../GeneralFunctions/throwNotification';
import KullaniciTable from './KullaniciTable/KullaniciTable';
import { useUserAuth } from '../../../Context/UserAuthContext';
import { DeleteOutlined } from '@ant-design/icons';
import { post } from '../../../api/request';

const Kullanicilar = () => {

    const {user} = useUserAuth();
    const [ loading, setLoading ] = React.useState(false);
    const initialMeta = { page:1, firstPage:1, lastPage:1, pagePerSize:10, filteredCount:0, orderBy:'ASC', sortBy:'id', viewCount:0 };
    const [ data, setData ] = React.useState({ users:[], meta:initialMeta });
    const [ selectedRows, setSelectedRows ] = React.useState([]);
    const [ selectOptions, setSelectOptions ] = React.useState({badges:[]});
    const [ query, setQuery ] = React.useState({
        pagePerSize:10,
        page:1,
        search:'',
        sortBy:'id',
        orderBy:'ASC'
    });
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [genderSelect, setGenderSelect] = React.useState(null);
    const [textArea, setTextArea] = React.useState(null);
    const [fileUrl, setFileUrl] = React.useState(null);

    const { TextArea } = Input;
 
    const getUserBadges = async () => {

        const request = await apiRequest({endpoint:'/user/get-user-badges'});

        if ( request.error || !request.responseData || !request.responseData.status ) {
            throwNotification({
                type:'error',
                message:'Bir hata oluştu',
                description: (request.responseData && request.responseData.message) ? request.responseData.message : 'Sunucu taraflı bir hata oluştu',
                duration:3
            });
            console.error('Alınan hata : ', request.errorMessage);
        }
        else{
            setSelectOptions({badges:request.responseData.response});
        }
    }

    const getUsers = React.useCallback( async () => {
        setLoading(true);
        let params = '?search='+query.search+'&pagePerSize='+query.pagePerSize+'&page='+query.page+'&orderBy='+query.orderBy+'&sortBy='+query.sortBy;
        const request = await apiRequest({endpoint:'/user/get-for-admin'+params, headers:{Authorization:user.token}});
        if ( request.error || !request.responseData || !request.responseData.status ) {
            throwNotification({
                type:'error',
                message:'Bir hata oluştu',
                description: (request.responseData && request.responseData.message) ? request.responseData.message : 'Sunucu taraflı bir hata oluştu',
                duration:3
            });
            console.error('Alınan hata : ', request.errorMessage);
        }
        else{
            const meta = request.responseData.response.meta;
            const data = request.responseData.response.data;
            setData( { users:data.filter( item => !item.userType.includes('SuperAdmin') ), meta:meta } );
        }
        await getUserBadges();
        setLoading(false);
    }, [query, user]);

    const debouncedHandleChange = React.useMemo(() => {
        return debounce((value) => {
            setQuery( { ...query, search: value, page:1 } );
        }, 700);
    }, [setQuery, query]);

    React.useEffect(() => {
        return () => {
            debouncedHandleChange.cancel();
        };
    }, [debouncedHandleChange]);

    React.useEffect( () => {
        getUsers();
    }, [getUsers])




    //Modal işlemleri
    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = async ()  => {
 

        const data = new FormData();
        data.append('mail_text', textArea);
        data.append('file_url', fileUrl);
        data.append('gender', genderSelect);
        data.append('user_mail_send_update', 1);
    
        try {
          const response = await post('/user_mail_send.php', data);
          console.log(response,"response?.status");
          if( response?.status == 200 ){

            alert("İşlem Başarılı")
            setIsModalOpen(false);
            setGenderSelect(null)
            setTextArea(null)
            setFileUrl(null)
          }else{
            console.error("Bir hata oluştu");

          }

        } catch (error) {
          console.error('Error submitting form:', error);
        }

        
      };


    
      const handleCancel = () => {
        setIsModalOpen(false);
      };

   


    return (
        <motion.div initial={{ opacity:0 }} animate={{ opacity:1 }}>
            
            <Helmet>
                <title>Kullanıcılar</title>
            </Helmet>

            
            <Input style={{marginBottom:20}} allowClear onChange={(e) => { !loading && debouncedHandleChange(e.target.value); }} placeholder='Arama Yapın'/>
            <Button type="primary" onClick={showModal}>Toplu Mail Gönder</Button>
            <KullaniciTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} loading={loading} data={data} setData={setData} query={query} setQuery={setQuery} selectOptions={selectOptions} setSelectOptions = {setSelectOptions} />
            
            {
                selectedRows.length > 0 &&
                <FloatButton.Group
                    shape="circle"
                    style={{
                        right: 50,
                    }}
                >
                    <FloatButton 
                        icon={<DeleteOutlined style={{color:'red'}}/>}
                        badge={{ count: selectedRows.length }} 
                        onClick={ async () => {
                            const body = JSON.stringify({users:[...selectedRows.map( selectedItem => selectedItem.id )]});
                            const request = await apiRequest( { body:body, endpoint:'/user/delete-user-admin', headers:{Authorization:user.token}, method:'DELETE' } );
                            if ( request.error || !request.responseData.status ) {
                                throwNotification({
                                    type:'error',
                                    message:'Bir hata oluştu',
                                    description: request.responseData ?  request.responseData.message : 'Kullanıcılar silinirken sunucu taraflı hata oluştu ',
                                    duration:3
                                });
                                request.errorMessage && console.error('Alınan hata : ', request.errorMessage );
                            }
                            else{

                                throwNotification({
                                    type:'success',
                                    message:'İşlem Başarılı',
                                    description: 'Çoklu Silme İşlemi Başarılı',
                                    duration:3
                                });

                                setQuery({...query});
                                setSelectedRows([]);
                            }
                        }}
                    />
                </FloatButton.Group>
            }
            
            <Modal title="Toplu mail gönderme" open={isModalOpen} onOk={handleOk} okText="Gönder" onCancel={handleCancel}>
            <Select

            style={{width:"100%",marginBottom:"10px"}}
            placeholder="Cinsiyet Seç"
            optionFilterProp="label"
            onChange={(val)=>  setGenderSelect(val) }
            options={[
            {
                label: 'Sadece Erkekler',
                value: 'man',
            },
            {
                label: 'Sadece Kadınlar',
                value: 'woman',
            },
            {
                label: 'Cinsiyet Belirtmeyenler',
                value: 'no-gender',
            },
            {
                label: 'Tümüne',
                value: 'all',
            },
            ]}
        /> 
            <TextArea rows={4} placeholder="Mail İçeriği" onChange={(e)=> setTextArea(e.target.value) } value={textArea} />
            <Input style={{marginBottom:20,marginTop:10}}  onChange={(e)=> setFileUrl(e.target.value) } value={fileUrl}  placeholder='Dosya linki'/>
         
            </Modal>
        </motion.div>
    );
}

export default Kullanicilar;