import React from 'react';
import { motion } from 'framer-motion';
import { useUserAuth } from '../../../../Context/UserAuthContext';
import apiRequest from '../../../../services';
import throwNotification from '../../../../GeneralFunctions/throwNotification';
import { Table } from 'antd';
import ButtonComponent from '../../../../GeneralComponents/ButtonComponent';


const Iletiler = () => {

    const {user} = useUserAuth();
    const initialMeta = { page:1, firstPage:1, lastPage:1, pagePerSize:10, filteredCount:0, orderBy:'ASC', sortBy:'id', viewCount:0 };
    const [ loading, setLoading ] = React.useState(true);
    const [ data, setData ] = React.useState({ notices:[], meta:initialMeta });
    const [ query, setQuery ] = React.useState({
        pagePerSize:10,
        page:1,
        search:'',
        sortBy:'id',
        orderBy:'ASC'
    });

    const getNotices = async () => {
        setLoading(true);
        let params = '?search='+query.search+'&pagePerSize='+query.pagePerSize+'&page='+query.page+'&orderBy='+query.orderBy+'&sortBy='+query.sortBy;
        const request = await apiRequest({endpoint:'/notice'+params});
        if ( request.error || !request.responseData || !request.responseData.status ) {
            throwNotification({
                type:'error',
                message:'Bir hata oluştu',
                description: (request.responseData && request.responseData.message) ? request.responseData.message : 'Sunucu taraflı bir hata oluştu',
                duration:3
            });
            console.error('Alınan hata : ', request.errorMessage);
        }
        else{
            const meta = request.responseData.response.meta;
            const data = request.responseData.response.data;
            setData( { notices:data, meta:meta } );
        }
        setLoading(false);
    }

    React.useEffect( () => {
        getNotices();
    }, [query])

    const columns = [
        { 
            title:'ID',
            dataIndex : 'id',
            key:'id',
            render : ( id, row ) => { return <span key={id}> {id} </span> },
            width:70,
            fixed:'left',
        },
        {
            title:'Yazılan Yazı',
            dataIndex : 'commentDatas',
            key:'commentDatas',
            width:250,
            render : ( commentDatas, row ) => { return <div>{commentDatas.comment}</div> }
        },
        {
            title:'Kullanıcı Adı',
            dataIndex : 'user',
            key:'user',
            width:150,
            render : ( user, row ) => <div>{user.username}</div>
        },
        {
            title:'Kullanıcı Aksiyonları',
            width:150,
            fixed:'right',
            render:(item) => { 
                return( 
                    <>
                        <ButtonComponent onClick = {async () => {
                            const request = await apiRequest({ endpoint:'/user/delete-user-admin/'+item.user.id, method:'DELETE', headers:{Authorization:user.token}})

                            if ( request.error || !request.responseData.status ) {
                                throwNotification({
                                    type:'error',
                                    duration:6,
                                    description: request.errorMessage || request.responseData.message,
                                    message:'Başarısız'                        
                                });
                                console.error('Error : ', request.errorMessage || request.responseData.message );
                                return false;
                            }

                            throwNotification({
                                type:'success',
                                duration:3,
                                description:'Kullanıcı Silindi',
                                message:'Başarılı'                        
                            });
                            setQuery({...query});
                        }} type={'primary'} style={{backgroundColor:'red', marginRight:10}}> Kullanıcıyı Sil </ButtonComponent>
                        <ButtonComponent onClick = {async () => {
                            const formData = new FormData();
                            formData.append('mode', 'disabled');
                            formData.append('newValue', 1);

                            const request = await apiRequest({ endpoint:'/user/update-user-admin/'+item.user.id, method:'POST', body:formData, headers:{Authorization:user.token}})

                            if ( request.error || !request.responseData.status ) {
                                throwNotification({
                                    type:'error',
                                    duration:6,
                                    description: request.errorMessage || request.responseData.message,
                                    message:'Başarısız'                        
                                });
                                console.error('Error : ', request.errorMessage || request.responseData.message );
                                return false;
                            }

                            throwNotification({
                                type:'success',
                                duration:3,
                                message:'Başarılı'                        
                            });
                            setQuery({...query});
                        }} type={'primary'} style={{backgroundColor:item.user.disable ? 'green' : 'red'}}> {item.user.disable ? 'Yasağı Aç' : 'Yasakla' } </ButtonComponent>
                    </>
                )
            },
        },
        {
            title:'#',
            width:50,
            fixed:'right',
            render:({id}) => { return <ButtonComponent type={'primary'} style={{backgroundColor:'red'}} onClick = { async () => {

                const request = await apiRequest({ endpoint:'/notice/'+id, method:'DELETE', headers:{Authorization:user.token}})

                if ( request.error || !request.responseData.status ) {
                    throwNotification({
                        type:'error',
                        duration:6,
                        description: 'Güncelleme yapılırken bir hata meydana geldi',
                        message:'Başarısız'                        
                    });
                    console.error('Error : ', request.errorMessage || request.responseData.message );
                    return false;
                }

                throwNotification({
                    type:'success',
                    duration:3,
                    description:'Güncelleme yapıldı',
                    message:'Başarılı'                        
                });
                setQuery({...query});
            }}> Şikayeti Sil </ButtonComponent>},
        },
    ]

    return (
        <motion.div initial={{ opacity:0 }} animate={{ opacity:1 }}>

            <Table
                columns={columns}
                loading         = {loading}
                scroll          = {{ y:600 }}
                dataSource      = { data.notices.map( item => { return { ...item, key:item.id }} ) || [] }
                pagination      = {{
                    total           : data.meta.filteredCount ? data.meta.filteredCount : 0,
                    position        : ['none', 'bottomRight'],
                    current         : query ? query.page : 0,
                    pageSize        : query ? query.pagePerSize : 10,
                    showSizeChanger : true,
                    onChange        : ( targetPage, pagePerSize ) => { setQuery({ ...query, page:targetPage, pagePerSize:pagePerSize });},
                }}
            />
        </motion.div>
    );
}

export default Iletiler;