const BASE_URL = 'https://dklist.com/api/public';
// const BASE_URL = 'http://127.0.0.1:8000';

const apiRequest = async ( { method = 'GET', body = null, headers = null, endpoint = '' } ) => {

    let options = { method : method };

    if ( body ) {
        options.body = body;
    }
    if( headers ){
        if ( headers.Authorization ) {
            headers.Authorization = 'Bearer '+headers.Authorization;
        }
        options.headers = headers;
    }

    try{
        const req = await fetch( BASE_URL+endpoint, { ...options });
        const response = await req.json();
        return { error:false, errorMessage:null, responseData: response }
    }
    catch (e) {
        return { error:true, errorMessage:e, responseData:null }
    }
}

export default apiRequest;